import "./App.css";
import * as React from "react";
import { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
export interface IAppProps {
  example?: string;
}
const Success = React.lazy(() => import("../Success/Success"));
const ActivationSuccess = React.lazy(() => import("../ActivationSuccess/ActivationSuccess"));
const Home = React.lazy(() => import("../Home2/Home"));


const CreateDomain = React.lazy(() => import("../CreateDomain/CreateDomain"));
const Subscription = React.lazy(() => import("../Subscription/Subscription"));

const PageNotFound = React.lazy(() => import("../PageNotFound/PageNotFound"));
const CreateAccount = React.lazy(() => import("../CreateAccount/CreateAccount"));
const CreateAdmin = React.lazy(() => import("../CreateAdmin/CreateAdmin"));
// const ForgotPassword = React.lazy(() => import("../ForgotPassword/ForgotPassword"));
// const Verification = React.lazy(() => import("../Verification/Verification"));
// const ChartOfAccounts = React.lazy(() => import("../../Components/TopSidebar/TopSidebar"));
// const AccountClass = React.lazy(() => import("../AccountClass/AccountClass"));
// const AccountGroup = React.lazy(() => import("../AccountGroup/AccountGroup"));
// const Accounts = React.lazy(() => import("../Accounts/Accounts"));
export default class App extends React.Component<IAppProps> {
  public render() {
    return (
      <Suspense fallback={
        <Dimmer active={true} inverted>
          <Loader size='large'>Initializing...</Loader>
        </Dimmer>
      }>
        <BrowserRouter>
          <Switch>
            {/* <Route exact={true} path="/accounts" component={Accounts} />
            <Route exact={true} path="/account-group" component={AccountGroup} />
            <Route exact={true} path="/account-class" component={AccountClass} />
            <Route exact={true} path="/chart-of-accounts" component={ChartOfAccounts} />
            <Route exact={true} path="/verification" component={Verification} /> */}
            <Route exact={true} path="/create-admin" component={CreateAdmin} />

            {/* <Route render={(props) => <ForgotPassword {...props} />} path="/forgot-password" /> */}
            <Route exact={true} path="/create-account" component={CreateAccount} />
            {/* <Route exact={true} path="/success" component={Success} /> */}
            <Route render={(props) => <Success {...props} />} path="/success" />
            <Route exact={true} path="/activation" component={ActivationSuccess} />



            <Route exact={true} path={['/', '/home']} component={Home} />
            <Route path="/subscriptions" component={Subscription} />


            <Route path="/domain/:id/:code" component={CreateDomain} />

            {/* Not Found */}
            <Route component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    );
  }
}
